import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Annotated services";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const Warning = makeShortcode("Warning");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "annotated-services",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#annotated-services",
        "aria-label": "annotated services permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Annotated services`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#mapping-http-service-methods"
          }}>{`Mapping HTTP service methods`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#parameter-injection"
          }}>{`Parameter injection`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#injecting-a-parameter-as-an-enum-type"
            }}>{`Injecting a parameter as an Enum type`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#getting-a-query-parameter"
            }}>{`Getting a query parameter`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#getting-an-http-header"
            }}>{`Getting an HTTP header`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#getting-a-custom-attribute-value"
            }}>{`Getting a custom attribute value`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#other-classes-automatically-injected"
            }}>{`Other classes automatically injected`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#handling-exceptions"
          }}>{`Handling exceptions`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#conversion-between-an-http-message-and-a-java-object"
          }}>{`Conversion between an HTTP message and a Java object`}</a></p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><a parentName="p" {...{
                "href": "#converting-an-http-request-to-a-java-object"
              }}>{`Converting an HTTP request to a Java object`}</a></p>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#injecting-value-of-parameters-and-http-headers-into-a-java-object"
                }}>{`Injecting value of parameters and HTTP headers into a Java object`}</a></li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li"><a parentName="p" {...{
                "href": "#converting-a-java-object-to-an-http-response"
              }}>{`Converting a Java object to an HTTP response`}</a></p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#specifying-a-blocking-task-executor"
          }}>{`Specifying a blocking task executor`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#using-serverbuilder-to-configure-converters-and-exception-handlers"
          }}>{`Using ServerBuilder to configure converters and exception handlers`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#returning-a-response"
          }}>{`Returning a response`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#decorating-an-annotated-service"
          }}>{`Decorating an annotated service`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#decorating-an-annotated-service-with-a-custom-decorator-annotation"
            }}>{`Decorating an annotated service with a custom decorator annotation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#evaluation-order-of-decorators"
            }}>{`Evaluation order of decorators`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#media-type-negotiation"
          }}>{`Media type negotiation`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#creating-user-defined-media-type-annotations"
            }}>{`Creating user-defined media type annotations`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#specifying-additional-response-headerstrailers"
          }}>{`Specifying additional response headers/trailers`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#using-a-composite-annotation"
          }}>{`Using a composite annotation`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#specifying-the-service-name"
          }}>{`Specifying the service name`}</a></p>
      </li>
    </ul>
    <Tip mdxType="Tip">
      <p>{`Visit `}<a parentName="p" {...{
          "href": "https://github.com/line/armeria-examples"
        }}>{`armeria-examples`}</a>{` to find a fully working example.`}</p>
    </Tip>
    <p>{`Armeria provides a way to write an HTTP service using annotations. It helps a user make his or her code
simple and easy to understand. A user is able to run an HTTP service by fewer lines of code using
annotations as follows. `}<inlineCode parentName="p">{`hello()`}</inlineCode>{` method in the example would be mapped to the path of `}<inlineCode parentName="p">{`/hello/{name}`}</inlineCode>{`
with an HTTP `}<inlineCode parentName="p">{`GET`}</inlineCode>{` method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ServerBuilder sb = Server.builder();
sb.annotatedService(new Object() {
    @Get("/hello/{name}")
    public HttpResponse hello(@Param("name") String name) {
        return HttpResponse.of(HttpStatus.OK,
                               MediaType.PLAIN_TEXT_UTF_8,
                               "Hello, %s!", name);
    }
});
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`For more information about using some well-known clients, please refer to
`}<a parentName="p" {...{
          "href": "/docs/advanced-client-interoperability#annotated-services"
        }}>{`Client interoperability`}</a>{`.`}</p>
    </Tip>
    <h2 {...{
      "id": "mapping-http-service-methods",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mapping-http-service-methods",
        "aria-label": "mapping http service methods permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mapping HTTP service methods`}</h2>
    <p>{`To map a service method in an annotated HTTP service class to an HTTP path, it has to be annotated with one of
HTTP method annotations. The following is the list of HTTP method annotations where each of them is mapped
to an HTTP method.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://@Get:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Get.html"
        }}>{`type://@Get`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://@Head:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Head.html"
        }}>{`type://@Head`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://@Post:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Post.html"
        }}>{`type://@Post`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://@Put:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Put.html"
        }}>{`type://@Put`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://@Delete:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Delete.html"
        }}>{`type://@Delete`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://@Options:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Options.html"
        }}>{`type://@Options`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://@Patch:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Patch.html"
        }}>{`type://@Patch`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://@Trace:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Trace.html"
        }}>{`type://@Trace`}</a></li>
    </ul>
    <p>{`To handle an HTTP request with a service method, you can annotate your service method simply as follows.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {
    @Get("/hello")
    public HttpResponse hello() { ... }
}
`}</code></pre>
    <p>{`There are 5 different path types that you can define:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Exact path, e.g. `}<inlineCode parentName="p">{`/hello`}</inlineCode>{` or `}<inlineCode parentName="p">{`exact:/hello`}</inlineCode></p>
        <ul parentName="li">
          <li parentName="ul">{`a service method will handle the path exactly matched with the specified path.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Prefix path, e.g. `}<inlineCode parentName="p">{`prefix:/hello`}</inlineCode></p>
        <ul parentName="li">
          <li parentName="ul">{`a service method will handle every path which starts with the specified prefix.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Path containing path variables, e.g `}<inlineCode parentName="p">{`/hello/{name}`}</inlineCode>{` or `}<inlineCode parentName="p">{`/hello/:name`}</inlineCode></p>
        <ul parentName="li">
          <li parentName="ul">{`a service method will handle the path matched with the specified path pattern. A path variable in the
specified pattern may be mapped to a parameter of the service method.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Regular expression path, e.g. `}<inlineCode parentName="p">{`regex:^/hello/(?<name>.*)$`}</inlineCode></p>
        <ul parentName="li">
          <li parentName="ul">{`a service method will handle the path matched with the specified regular expression. If a named capturing
group exists in the regular expression, it may be mapped to a parameter of the service method.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Glob pattern path, e.g. `}<inlineCode parentName="p">{`glob:/*/hello/**`}</inlineCode></p>
        <ul parentName="li">
          <li parentName="ul">{`a service method will handle the path matched with the specified glob pattern. Each wildcard is mapped to
an index which starts with `}<inlineCode parentName="li">{`0`}</inlineCode>{`, so it may be mapped to a parameter of the service method.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`You can get the value of a path variable, a named capturing group of the regular expression or wildcards of
the glob pattern in your service method by annotating a parameter with `}<a parentName="p" {...{
        "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
      }}>{`type://@Param`}</a>{` as follows.
Please refer to `}<a parentName="p" {...{
        "href": "#parameter-injection"
      }}>{`Parameter injection`}</a>{` for more information about `}<a parentName="p" {...{
        "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
      }}>{`type://@Param`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    @Get("/hello/{name}")
    public HttpResponse pathVar(@Param("name") String name) { ... }

    @Get("regex:^/hello/(?<name>.*)$")
    public HttpResponse regex(@Param("name") String name) { ... }

    @Get("glob:/*/hello/**")
    public HttpResponse glob(@Param("0") String prefix,
                             @Param("1") String name) { ... }
}
`}</code></pre>
    <p>{`Every service method in the examples so far had a single HTTP method annotation with it. What if you want
to map more than one HTTP method or path to your service method? You can use `}<a parentName="p" {...{
        "href": "type://@Path:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Path.html"
      }}>{`type://@Path`}</a>{` annotations to
specify multiple paths, and use the HTTP method annotations without a path to map multiple HTTP methods, e.g.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {
    @Get
    @Post
    @Put
    @Delete
    @Path("/hello")
    @Path("/hi")
    public HttpResponse greeting() { ... }
}
`}</code></pre>
    <p>{`Every service method assumes that it returns an HTTP response with `}<inlineCode parentName="p">{`200 OK`}</inlineCode>{` or `}<inlineCode parentName="p">{`204 No Content`}</inlineCode>{` status
according to its return type. If the return type is `}<inlineCode parentName="p">{`void`}</inlineCode>{` or `}<inlineCode parentName="p">{`Void`}</inlineCode>{`, `}<inlineCode parentName="p">{`204 No Content`}</inlineCode>{` would be applied.
`}<inlineCode parentName="p">{`200 OK`}</inlineCode>{` would be applied for the other types. If you want to return an alternative status code for a method,
you can use `}<a parentName="p" {...{
        "href": "type://@StatusCode:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/StatusCode.html"
      }}>{`type://@StatusCode`}</a>{` annotation as follows.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    @StatusCode(201)
    @Post("/users/{name}")
    public User createUser(@Param("name") String name) { ... }

    // @StatusCode(200) would be applied by default.
    @Get("/users/{name}")
    public User getUser(@Param("name") String name) { ... }

    // @StatusCode(204) would be applied by default.
    @Delete("/users/{name}")
    public void deleteUser(@Param("name") String name) { ... }
}
`}</code></pre>
    <p>{`You can define a service method which handles a request only if it contains a header or parameter the method
requires. The following methods are bound to the same path `}<inlineCode parentName="p">{`/users`}</inlineCode>{` but a request may be routed based on the
`}<inlineCode parentName="p">{`client-type`}</inlineCode>{` header.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    // Handles a request which contains 'client-type: android' header.
    @Get("/users")
    @MatchesHeader("client-type=android")
    public User getUsers1() { ... }

    // Handles a request which contains 'client-type' header.
    // Any values of the 'client-type' header are accepted.
    @Get("/users")
    @MatchesHeader("client-type")
    public User getUsers2() { ... }

    // Handles a request which doesn't contain 'client-type' header.
    @Get("/users")
    public User getUsers3() { ... }
}
`}</code></pre>
    <h2 {...{
      "id": "parameter-injection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#parameter-injection",
        "aria-label": "parameter injection permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Parameter injection`}</h2>
    <p>{`Let's see the example in the above section again.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    @Get("/hello/{name}")
    public HttpResponse pathvar(@Param("name") String name) { ... }

    @Get("regex:^/hello/(?<name>.*)$")
    public HttpResponse regex(@Param("name") String name) { ... }

    @Get("glob:/*/hello/**")
    public HttpResponse glob(@Param("0") String prefix,
                             @Param("1") String name) { ... }
}
`}</code></pre>
    <p>{`A value of a parameter `}<inlineCode parentName="p">{`name`}</inlineCode>{` is automatically injected as a `}<inlineCode parentName="p">{`String`}</inlineCode>{` by Armeria.
Armeria will try to convert the value appropriately if the parameter type is not `}<inlineCode parentName="p">{`String`}</inlineCode>{`.
`}<inlineCode parentName="p">{`IllegalArgumentException`}</inlineCode>{` will be raised if the conversion fails or the parameter type is not
one of the following supported types:`}</p>
    <ul>
      <li parentName="ul">{`Primitive types and their wrapper types:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`boolean`}</inlineCode>{` or `}<inlineCode parentName="li">{`Boolean`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`byte`}</inlineCode>{` or `}<inlineCode parentName="li">{`Byte`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`short`}</inlineCode>{` or `}<inlineCode parentName="li">{`Short`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`integer`}</inlineCode>{` or `}<inlineCode parentName="li">{`Integer`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`long`}</inlineCode>{` or `}<inlineCode parentName="li">{`Long`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`float`}</inlineCode>{` or `}<inlineCode parentName="li">{`Float`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`double`}</inlineCode>{` or `}<inlineCode parentName="li">{`Double`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{`Well-known JDK classes:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`String`}</inlineCode>{`, `}<inlineCode parentName="li">{`CharSequence`}</inlineCode>{` or `}<inlineCode parentName="li">{`AsciiString`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`Enum`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`UUID`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`java.time`}</inlineCode>{` package classes`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`Instant`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`Duration`}</inlineCode>{` and  `}<inlineCode parentName="li">{`Period`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`LocalDate`}</inlineCode>{`, `}<inlineCode parentName="li">{`LocalDateTime`}</inlineCode>{` and `}<inlineCode parentName="li">{`LocalTime`}</inlineCode>{`,`}</li>
              <li parentName="ul"><inlineCode parentName="li">{`OffsetDateTime`}</inlineCode>{` and `}<inlineCode parentName="li">{`OffsetTime`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`ZonedDateTime`}</inlineCode>{`, `}<inlineCode parentName="li">{`ZoneId`}</inlineCode>{` and `}<inlineCode parentName="li">{`ZoneOffset`}</inlineCode></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Custom types with either of the following public static methods/constructor taking a single `}<inlineCode parentName="li">{`String`}</inlineCode>{` parameter.
Note that these are scanned for in the order below. The first matching method being is used,
and if that method throws an exception, no other methods will be tried and that exception will
be propagated.`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`public static T of(String)`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`public static T valueOf(String)`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`public static T fromString(String)`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`public T(String)`}</inlineCode>{` (constructor)`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Note that you can omit the value of `}<a parentName="p" {...{
        "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
      }}>{`type://@Param`}</a>{` if you compiled your code with `}<inlineCode parentName="p">{`-parameters`}</inlineCode>{` javac
option. In this case the variable name is used as the value.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {
    @Get("/hello/{name}")
    public HttpResponse hello1(@Param String name) { ... }
}
`}</code></pre>
    <p>{`Please refer to `}<a parentName="p" {...{
        "href": "/docs/setup#configure--parameters-javac-option"
      }}>{`Configure -parameters javac option`}</a>{`
for more information.`}</p>
    <h3 {...{
      "id": "injecting-a-parameter-as-an-enum-type",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#injecting-a-parameter-as-an-enum-type",
        "aria-label": "injecting a parameter as an enum type permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Injecting a parameter as an `}<inlineCode parentName="h3">{`Enum`}</inlineCode>{` type`}</h3>
    <p><inlineCode parentName="p">{`Enum`}</inlineCode>{` type is also automatically converted if you annotate a parameter of your service method with
`}<a parentName="p" {...{
        "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
      }}>{`type://@Param`}</a>{` annotation. If your `}<inlineCode parentName="p">{`Enum`}</inlineCode>{` type can be handled in a case-insensitive way, Armeria
automatically converts the string value of a parameter to a value of `}<inlineCode parentName="p">{`Enum`}</inlineCode>{` in a case-insensitive way.
Otherwise, case-sensitive exact match will be performed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public enum CaseInsensitive {
    ALPHA, BRAVO, CHARLIE
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public enum CaseSensitive {
    ALPHA, alpha
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    @Get("/hello1/{there}")
    public HttpResponse hello1(@Param("there") CaseInsensitive there) {
        // 'there' is converted in a case-insensitive way.
    }

    @Get("/hello2/{there}")
    public HttpResponse hello2(@Param("there") CaseSensitive there) {
        // 'there' must be converted in a case-sensitive way.
        // So 'ALPHA' and 'alpha' are only acceptable.
    }
}
`}</code></pre>
    <h3 {...{
      "id": "getting-a-query-parameter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#getting-a-query-parameter",
        "aria-label": "getting a query parameter permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Getting a query parameter`}</h3>
    <p>{`When the value of `}<a parentName="p" {...{
        "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
      }}>{`type://@Param`}</a>{` annotation is not shown in the path pattern, it will be handled as a
parameter name of the query string of the request. If you have a service class like the example below and
a user sends an HTTP `}<inlineCode parentName="p">{`GET`}</inlineCode>{` request with URI of `}<inlineCode parentName="p">{`/hello1?name=armeria`}</inlineCode>{`, the service method will get `}<inlineCode parentName="p">{`armeria`}</inlineCode>{`
as the value of parameter `}<inlineCode parentName="p">{`name`}</inlineCode>{`.`}</p>
    <p>{`If there is no parameter named `}<inlineCode parentName="p">{`name`}</inlineCode>{` in the query string, the service method that requires it will not be
invoked, but the client will get a `}<inlineCode parentName="p">{`400 Bad Request`}</inlineCode>{` response.  If you want to allow `}<inlineCode parentName="p">{`null`}</inlineCode>{` to be injected,
you can use `}<a parentName="p" {...{
        "href": "type://@Default:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Default.html"
      }}>{`type://@Default`}</a>{` annotation, `}<inlineCode parentName="p">{`@Nullable`}</inlineCode>{` annotation or `}<inlineCode parentName="p">{`Optional<?>`}</inlineCode>{` class, like demonstrated
below in `}<inlineCode parentName="p">{`hello2()`}</inlineCode>{`, `}<inlineCode parentName="p">{`hello3()`}</inlineCode>{` and `}<inlineCode parentName="p">{`hello4()`}</inlineCode>{` methods:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    @Get("/hello1")
    // Will not be invoked but return a 400 status when 'name' parameter is missing.
    public HttpResponse hello1(@Param("name") String name) { ... }

    @Get("/hello2")
    public HttpResponse hello2(@Param("name") @Default("armeria") String name) { ... }

    @Get("/hello3")
    public HttpResponse hello3(@Param("name") @Nullable String name) {
        String clientName = name != null ? name : "armeria";
        // ...
    }

    @Get("/hello4")
    public HttpResponse hello4(@Param("name") Optional<String> name) {
        String clientName = name.orElse("armeria");
        // ...
    }
}
`}</code></pre>
    <Tip mdxType="Tip">
      <p><inlineCode parentName="p">{`@Nullable`}</inlineCode>{` annotation can be from `}<em parentName="p">{`any`}</em>{` package as long as its simple name is `}<inlineCode parentName="p">{`Nullable`}</inlineCode>{`.
For example, it can be `}<inlineCode parentName="p">{`@javax.annotation.Nullable`}</inlineCode>{`, `}<inlineCode parentName="p">{`@org.checkerframework.checker.nullness.qual.Nullable`}</inlineCode>{` or
`}<inlineCode parentName="p">{`@io.reactivex.annotations.Nullable`}</inlineCode>{`.`}</p>
    </Tip>
    <p>{`If multiple parameters exist with the same name in a query string, they can be injected as a `}<inlineCode parentName="p">{`List<?>`}</inlineCode>{`
or `}<inlineCode parentName="p">{`Set<?>`}</inlineCode>{`, e.g. `}<inlineCode parentName="p">{`/hello1?number=1&number=2&number=3`}</inlineCode>{`. You can use `}<a parentName="p" {...{
        "href": "type://@Default:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Default.html"
      }}>{`type://@Default`}</a>{` annotation
or `}<inlineCode parentName="p">{`Optional<?>`}</inlineCode>{` class here, too.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {
    @Get("/hello1")
    public HttpResponse hello1(@Param("number") List<Integer> numbers) { ... }

    // If there is no 'number' parameter, the default value "1" will be converted to
    // Integer 1, then it will be added to the 'numbers' list.
    @Get("/hello2")
    public HttpResponse hello2(@Param("number") @Default("1")
                               List<Integer> numbers) { ... }

    @Get("/hello3")
    public HttpResponse hello3(@Param("number")
                               Optional<List<Integer>> numbers) { ... }
}
`}</code></pre>
    <p>{`If you specify query delimiter while building an annotated service, or a `}<a parentName="p" {...{
        "href": "type://@Delimiter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Delimiter.html"
      }}>{`type://@Delimiter`}</a>{` annotation with a
query parameter, a single parameter will be converted into separated values if the parameter is mapped to a
`}<inlineCode parentName="p">{`List<?>`}</inlineCode>{` or `}<inlineCode parentName="p">{`Set<?>`}</inlineCode>{`, e.g. `}<inlineCode parentName="p">{`/hello1?number=1,2,3`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`sb.annotatedService()
  .queryDelimiter(",")
  .build(new MyAnnotatedService());
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {
    @Get("/hello1")
    public HttpResponse hello1(@Param("number") @Delimiter(",") List<Integer> numbers) { ... }
}
`}</code></pre>
    <p>{`If an HTTP `}<inlineCode parentName="p">{`POST`}</inlineCode>{` request with a `}<inlineCode parentName="p">{`Content-Type: x-www-form-urlencoded`}</inlineCode>{` header is received and
no `}<a parentName="p" {...{
        "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
      }}>{`type://@Param`}</a>{` value appears in the path pattern, Armeria will aggregate the received request and
decode its body as a URL-encoded form. After that, Armeria will inject the decoded value into the parameter.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {
    @Post("/hello4")
    public HttpResponse hello4(@Param("name") String name) {
        // 'x-www-form-urlencoded' request will be aggregated.
        // The other requests may get a '400 Bad Request' because
        // there is no way to inject a mandatory parameter 'name'.
    }
}
`}</code></pre>
    <h3 {...{
      "id": "getting-an-http-header",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#getting-an-http-header",
        "aria-label": "getting an http header permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Getting an HTTP header`}</h3>
    <p>{`Armeria also provides `}<a parentName="p" {...{
        "href": "type://@Header:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Header.html"
      }}>{`type://@Header`}</a>{` annotation to inject an HTTP header value into a parameter.
The parameter annotated with `}<a parentName="p" {...{
        "href": "type://@Header:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Header.html"
      }}>{`type://@Header`}</a>{` can also be specified as one of the built-in types as follows.
`}<a parentName="p" {...{
        "href": "type://@Default:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Default.html"
      }}>{`type://@Default`}</a>{` and `}<inlineCode parentName="p">{`Optional<?>`}</inlineCode>{` are also supported. `}<a parentName="p" {...{
        "href": "type://@Header:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Header.html"
      }}>{`type://@Header`}</a>{` annotation also supports
`}<inlineCode parentName="p">{`List<?>`}</inlineCode>{` or `}<inlineCode parentName="p">{`Set<?>`}</inlineCode>{` because HTTP headers can be added several times with the same name.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    @Get("/hello1")
    public HttpResponse hello1(@Header("Authorization") String auth) { ... }

    @Post("/hello2")
    public HttpResponse hello2(@Header("Content-Length") long contentLength) { ... }

    @Post("/hello3")
    public HttpResponse hello3(@Header("Forwarded") List<String> forwarded) { ... }

    @Post("/hello4")
    public HttpResponse hello4(@Header("Forwarded")
                               Optional<Set<String>> forwarded) { ... }
}
`}</code></pre>
    <p>{`Note that you can omit the value of `}<a parentName="p" {...{
        "href": "type://@Header:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Header.html"
      }}>{`type://@Header`}</a>{` if you compiled your code with `}<inlineCode parentName="p">{`-parameters`}</inlineCode>{` javac
option. Read `}<a parentName="p" {...{
        "href": "#parameter-injection"
      }}>{`Parameter injection`}</a>{` for more information.
In this case, the variable name is used as the value, but it will be converted to hyphen-separated lowercase
string to be suitable for general HTTP header names. e.g. a variable name `}<inlineCode parentName="p">{`contentLength`}</inlineCode>{` or
`}<inlineCode parentName="p">{`content_length`}</inlineCode>{` will be converted to `}<inlineCode parentName="p">{`content-length`}</inlineCode>{` as the value of `}<a parentName="p" {...{
        "href": "type://@Header:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Header.html"
      }}>{`type://@Header`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {
    @Post("/hello2")
    public HttpResponse hello2(@Header long contentLength) { ... }
}
`}</code></pre>
    <h3 {...{
      "id": "getting-a-custom-attribute-value",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#getting-a-custom-attribute-value",
        "aria-label": "getting a custom attribute value permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Getting a custom attribute value`}</h3>
    <p>{`Armeria also provides the `}<a parentName="p" {...{
        "href": "type://@Attribute:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Attribute.html"
      }}>{`type://@Attribute`}</a>{` annotation to inject a
custom attribute value of a `}<a parentName="p" {...{
        "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
      }}>{`type://RequestContext`}</a>{` into the annotated
parameter. The `}<a parentName="p" {...{
        "href": "type://@Attribute:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Attribute.html"
      }}>{`type://@Attribute`}</a>{` annotation has two attributes:
`}<inlineCode parentName="p">{`value`}</inlineCode>{` and  `}<inlineCode parentName="p">{`prefix`}</inlineCode>{`, as shown in the example below. If both `}<inlineCode parentName="p">{`prefix`}</inlineCode>{`
and `}<inlineCode parentName="p">{`value`}</inlineCode>{` are specified, `}<inlineCode parentName="p">{`AttributeKey.valueOf(prefix, value)`}</inlineCode>{` will
be used to look up the `}<inlineCode parentName="p">{`AttributeKey`}</inlineCode>{` of the custom attribute.
If `}<inlineCode parentName="p">{`prefix`}</inlineCode>{` is unspecified, the class of the annotated service will be
tried as the `}<inlineCode parentName="p">{`prefix`}</inlineCode>{` (e.g. `}<inlineCode parentName="p">{`Attribute.valueOf(MyAnnotatedService.class, value)`}</inlineCode>{`),
and then if there's no such attribute, `}<inlineCode parentName="p">{`Attribute.valueOf(value)`}</inlineCode>{`
will be tried as a fallback.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAttributes {
    public static final AttributeKey<String> USERNAME =
            AttributeKey.valueOf(MyAttributes.class, "USERNAME");
}

public class MyAnnotatedService {

    // Uses \`AttributeKey.valueOf(MyAttributes.class, "USERNAME")\`
    @Get("/hello1")
    public HttpResponse hello1(@Attribute(prefix = MyAttributes.class,
                                          value = "USERNAME")
                               String username) { ... }

    // Uses \`AttributeKey.valueOf(MyAnnotatedService.class, "USERNAME")\`
    // if possible. If non-existent, use \`AttributeKey.valueOf("USERNAME")\`.
    @Post("/hello2")
    public HttpResponse hello2(@Attribute("USERNAME") String username) { ... }
}
`}</code></pre>
    <p>{`Note that if no attribute for the specified `}<inlineCode parentName="p">{`AttributeKey`}</inlineCode>{` is found in `}<a parentName="p" {...{
        "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
      }}>{`type://RequestContext`}</a>{`, `}<inlineCode parentName="p">{`IllegalArgumentException`}</inlineCode>{` will occur at runtime.`}</p>
    <h3 {...{
      "id": "other-classes-automatically-injected",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#other-classes-automatically-injected",
        "aria-label": "other classes automatically injected permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other classes automatically injected`}</h3>
    <p>{`The following classes are automatically injected when you specify them on the parameter list of your method.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
        }}>{`type://ServiceRequestContext`}</a>{` (or `}<a parentName="li" {...{
          "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
        }}>{`type://RequestContext`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://RequestHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestHeaders.html"
        }}>{`type://RequestHeaders`}</a>{` (or `}<a parentName="li" {...{
          "href": "type://HttpHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpHeaders.html"
        }}>{`type://HttpHeaders`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
        }}>{`type://HttpRequest`}</a>{` (or `}<a parentName="li" {...{
          "href": "type://Request:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Request.html"
        }}>{`type://Request`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://AggregatedHttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/AggregatedHttpRequest.html"
        }}>{`type://AggregatedHttpRequest`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://QueryParams:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/QueryParams.html"
        }}>{`type://QueryParams`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://Cookies:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Cookies.html"
        }}>{`type://Cookies`}</a></li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    @Get("/hello1")
    public HttpResponse hello1(ServiceRequestContext ctx, HttpRequest req) {
        // Use the context and request inside a method.
    }

    @Post("/hello2")
    public HttpResponse hello2(AggregatedHttpRequest aggregatedRequest) {
        // Armeria aggregates the received HttpRequest and
        // calls this method with the aggregated request.
    }

    @Get("/hello3")
    public HttpResponse hello3(QueryParams params) {
        // 'params' holds the parameters parsed from a query string of a request.
    }

    @Post("/hello4")
    public HttpResponse hello4(QueryParams params) {
        // If a request has a url-encoded form as its body,
        // it can be accessed via 'params'.
    }

    @Post("/hello5")
    public HttpResponse hello5(Cookies cookies) {
        // If 'Cookie' header exists, it will be injected into
        // the specified 'cookies' parameter.
    }
}
`}</code></pre>
    <h2 {...{
      "id": "handling-exceptions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#handling-exceptions",
        "aria-label": "handling exceptions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Handling exceptions`}</h2>
    <p>{`It is often useful to extract exception handling logic from service methods into a separate common class.
Armeria provides `}<a parentName="p" {...{
        "href": "type://@ExceptionHandler:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ExceptionHandler.html"
      }}>{`type://@ExceptionHandler`}</a>{` annotation to transform an exception into a response.
You can write your own exception handler by implementing `}<a parentName="p" {...{
        "href": "type://ExceptionHandlerFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ExceptionHandlerFunction.html"
      }}>{`type://ExceptionHandlerFunction`}</a>{` interface and
annotate your service object or method with `}<a parentName="p" {...{
        "href": "type://@ExceptionHandler:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ExceptionHandler.html"
      }}>{`type://@ExceptionHandler`}</a>{` annotation. Here is an example of
an exception handler. If your exception handler is not able to handle a given exception, you can call
`}<a parentName="p" {...{
        "href": "type://ExceptionHandlerFunction#fallthrough():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ExceptionHandlerFunction.html#fallthrough()"
      }}>{`type://ExceptionHandlerFunction#fallthrough()`}</a>{` to pass the exception to the next exception handler.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyExceptionHandler implements ExceptionHandlerFunction {
    @Override
    public HttpResponse handleException(ServiceRequestContext ctx,
                                        HttpRequest req, Throwable cause) {
        if (cause instanceof MyServiceException) {
            return HttpResponse.of(HttpStatus.CONFLICT);
        }

        // To the next exception handler.
        return ExceptionHandlerFunction.fallthrough();
    }
}
`}</code></pre>
    <p>{`You can annotate at class level to catch an exception from every method in your service class.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@ExceptionHandler(MyExceptionHandler.class)
public class MyAnnotatedService {
    @Get("/hello")
    public HttpResponse hello() { ... }
}
`}</code></pre>
    <p>{`You can also annotate at method level to catch an exception from a single method in your service class.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {
    @Get("/hello")
    @ExceptionHandler(MyExceptionHandler.class)
    public HttpResponse hello() { ... }
}
`}</code></pre>
    <p>{`If there is no exception handler which is able to handle an exception, the exception would be passed to
the default exception handler. It handles `}<inlineCode parentName="p">{`IllegalArgumentException`}</inlineCode>{`, `}<a parentName="p" {...{
        "href": "type://HttpStatusException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpStatusException.html"
      }}>{`type://HttpStatusException`}</a>{` and
`}<a parentName="p" {...{
        "href": "type://HttpResponseException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpResponseException.html"
      }}>{`type://HttpResponseException`}</a>{` by default. `}<inlineCode parentName="p">{`IllegalArgumentException`}</inlineCode>{` would be converted into
`}<inlineCode parentName="p">{`400 Bad Request`}</inlineCode>{` response, and the other two exceptions would be converted into a response with
the status code which they are holding. For another exceptions, `}<inlineCode parentName="p">{`500 Internal Server Error`}</inlineCode>{` would be
sent to the client.`}</p>
    <h2 {...{
      "id": "conversion-between-an-http-message-and-a-java-object",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#conversion-between-an-http-message-and-a-java-object",
        "aria-label": "conversion between an http message and a java object permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Conversion between an HTTP message and a Java object`}</h2>
    <h3 {...{
      "id": "converting-an-http-request-to-a-java-object",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#converting-an-http-request-to-a-java-object",
        "aria-label": "converting an http request to a java object permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Converting an HTTP request to a Java object`}</h3>
    <p>{`In some cases like receiving a JSON document from a client, it may be useful to convert the document to
a Java object automatically. Armeria provides `}<a parentName="p" {...{
        "href": "type://@RequestConverter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/RequestConverter.html"
      }}>{`type://@RequestConverter`}</a>{` and `}<a parentName="p" {...{
        "href": "type://@RequestObject:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/RequestObject.html"
      }}>{`type://@RequestObject`}</a>{`
annotations so that such conversion can be done conveniently.
You can write your own request converter by implementing `}<a parentName="p" {...{
        "href": "type://RequestConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/RequestConverterFunction.html"
      }}>{`type://RequestConverterFunction`}</a>{` as follows.
Similar to the exception handler, you can call `}<a parentName="p" {...{
        "href": "type://RequestConverterFunction#fallthrough():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/RequestConverterFunction.html#fallthrough()"
      }}>{`type://RequestConverterFunction#fallthrough()`}</a>{` when your request
converter is not able to convert the request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class ToEnglishConverter implements RequestConverterFunction {
    @Override
    public Object convertRequest(ServiceRequestContext ctx,
                                 AggregatedHttpRequest request,
                                 Class<?> expectedResultType) {
        if (expectedResultType == Greeting.class) {
            // Convert the request to a Java object.
            return new Greeting(translateToEnglish(request.contentUtf8()));
        }

        // To the next request converter.
        return RequestConverterFunction.fallthrough();
    }

    private String translateToEnglish(String greetingInAnyLanguage) { ... }
}
`}</code></pre>
    <p>{`Then, you can write your service method as follows. Custom request objects will be converted automatically
by the converters you registered with `}<a parentName="p" {...{
        "href": "type://@RequestConverter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/RequestConverter.html"
      }}>{`type://@RequestConverter`}</a>{` annotation.
Note that `}<a parentName="p" {...{
        "href": "type://@RequestConverter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/RequestConverter.html"
      }}>{`type://@RequestConverter`}</a>{` annotation can be specified on a class, a method or a parameter
in an annotated service, and its scope is determined depending on where it is specified.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@RequestConverter(ToEnglishConverter.class)
public class MyAnnotatedService {

    @Post("/hello")
    public HttpResponse hello(Greeting greeting) {
        // ToEnglishConverter will be used to convert a request.
        // ...
    }

    @Post("/hola")
    @RequestConverter(ToSpanishConverter.class)
    public HttpResponse hola(Greeting greeting) {
        // ToSpanishConverter will be tried to convert a request first.
        // ToEnglishConverter will be used if ToSpanishConverter fell through.
        // ...
    }

    @Post("/greet")
    public HttpResponse greet(@RequestConverter(ToGermanConverter.class)
                              Greeting greetingInGerman,
                              Greeting greetingInEnglish) {
        // For the 1st parameter 'greetingInGerman':
        // ToGermanConverter will be tried to convert a request first.
        // ToEnglishConverter will be used if ToGermanConverter fell through.
        //
        // For the 2nd parameter 'greetingInEnglish':
        // ToEnglishConverter will be used to convert a request.
        // ...
    }
}
`}</code></pre>
    <p>{`Armeria also provides built-in request converters such as, a request converter for a Java Bean,
`}<a parentName="p" {...{
        "href": "type://JacksonRequestConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/JacksonRequestConverterFunction.html"
      }}>{`type://JacksonRequestConverterFunction`}</a>{` for a JSON document, `}<a parentName="p" {...{
        "href": "type://StringRequestConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/StringRequestConverterFunction.html"
      }}>{`type://StringRequestConverterFunction`}</a>{`
for a string and `}<a parentName="p" {...{
        "href": "type://ByteArrayRequestConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ByteArrayRequestConverterFunction.html"
      }}>{`type://ByteArrayRequestConverterFunction`}</a>{` for binary data. They will be applied
after your request converters, so you don't have to specify any `}<a parentName="p" {...{
        "href": "type://@RequestConverter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/RequestConverter.html"
      }}>{`type://@RequestConverter`}</a>{` annotations:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    // JacksonRequestConverterFunction will work for the content type of
    // 'application/json' or one of '+json' types.
    @Post("/hello1")
    public HttpResponse hello1(JsonNode body) { ... }

    @Post("/hello2")
    public HttpResponse hello2(MyJsonRequest body) { ... }

    // StringRequestConverterFunction will work regardless of the content type.
    @Post("/hello3")
    public HttpResponse hello3(String body) { ... }

    @Post("/hello4")
    public HttpResponse hello4(CharSequence body) { ... }

    // ByteArrayRequestConverterFunction will work regardless of the content type.
    @Post("/hello5")
    public HttpResponse hello5(byte[] body) { ... }

    @Post("/hello6")
    public HttpResponse hello6(HttpData body) { ... }
}
`}</code></pre>
    <h4 {...{
      "id": "injecting-value-of-parameters-and-http-headers-into-a-java-object",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#injecting-value-of-parameters-and-http-headers-into-a-java-object",
        "aria-label": "injecting value of parameters and http headers into a java object permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Injecting value of parameters and HTTP headers into a Java object`}</h4>
    <p>{`Armeria provides a generic built-in request converter that converts a request into a Java object.
Just define a plain old Java class and specify it as a parameter of your service method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {
    @Post("/hello")
    public HttpResponse hello(MyRequestObject myRequestObject) { ... }
}
`}</code></pre>
    <p>{`We also need to define the `}<inlineCode parentName="p">{`MyRequestObject`}</inlineCode>{` class which was used in the method `}<inlineCode parentName="p">{`hello()`}</inlineCode>{` above.
To tell Armeria which constructor parameter, setter method or field has to be injected with what value,
we should put `}<a parentName="p" {...{
        "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
      }}>{`type://@Param`}</a>{`, `}<a parentName="p" {...{
        "href": "type://@Header:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Header.html"
      }}>{`type://@Header`}</a>{`, `}<a parentName="p" {...{
        "href": "type://@RequestObject:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/RequestObject.html"
      }}>{`type://@RequestObject`}</a>{` annotations on any of the following elements:`}</p>
    <ul>
      <li parentName="ul">{`Fields`}</li>
      <li parentName="ul">{`Constructors with only one parameter`}</li>
      <li parentName="ul">{`Methods with only one parameter`}</li>
      <li parentName="ul">{`Constructor parameters`}</li>
      <li parentName="ul">{`Method parameters`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyRequestObject {
    @Param("name") // This field will be injected by the value of parameter "name".
    private String name;

    @Header("age") // This field will be injected by the value of HTTP header "age".
    private int age;

    @RequestObject // This field will be injected by another request converter.
    private MyAnotherRequestObject obj;

    // You can omit the value of @Param or @Header if you compiled your code with
    // \`-parameters\` javac option.
    @Param // This field will be injected by the value of parameter "gender".
    private String gender;

    @Header // This field will be injected by the value of "accept-language" header.
    private String acceptLanguage;

    @Param("address") // You can annotate a single-parameter method
                      // with @Param or @Header.
    public void setAddress(String address) { ... }

    @Header("id") // You can annotate a single-parameter constructor
    @Default("0") // with @Param or @Header.
    public MyRequestObject(long id) { ... }

    // You can annotate all parameters of method or constructor with @Param or @Header.
    public void init(@Header("permissions") String permissions,
                     @Param("client-id") @Default("0") int clientId)
}
`}</code></pre>
    <p>{`The usage of `}<a parentName="p" {...{
        "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
      }}>{`type://@Param`}</a>{` or `}<a parentName="p" {...{
        "href": "type://@Header:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Header.html"
      }}>{`type://@Header`}</a>{` annotations on Java object elements is much like
using them on the parameters of a service method because even you can use `}<a parentName="p" {...{
        "href": "type://@Default:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Default.html"
      }}>{`type://@Default`}</a>{` and
`}<a parentName="p" {...{
        "href": "type://@RequestObject:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/RequestObject.html"
      }}>{`type://@RequestObject`}</a>{` annotations defined there.
Please refer to `}<a parentName="p" {...{
        "href": "#parameter-injection"
      }}>{`Parameter injection`}</a>{`, and
`}<a parentName="p" {...{
        "href": "#getting-an-http-header"
      }}>{`Getting an HTTP header`}</a>{` for more information.`}</p>
    <h3 {...{
      "id": "converting-a-java-object-to-an-http-response",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#converting-a-java-object-to-an-http-response",
        "aria-label": "converting a java object to an http response permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Converting a Java object to an HTTP response`}</h3>
    <p>{`Every object returned by an annotated service method can be converted to an HTTP response message by
response converters, except for `}<a parentName="p" {...{
        "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
      }}>{`type://HttpResponse`}</a>{` and `}<a parentName="p" {...{
        "href": "type://AggregatedHttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/AggregatedHttpResponse.html"
      }}>{`type://AggregatedHttpResponse`}</a>{` which are already
in a form of response message. You can also write your own response converter by implementing
`}<a parentName="p" {...{
        "href": "type://ResponseConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ResponseConverterFunction.html"
      }}>{`type://ResponseConverterFunction`}</a>{` as follows. Also similar to `}<a parentName="p" {...{
        "href": "type://RequestConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/RequestConverterFunction.html"
      }}>{`type://RequestConverterFunction`}</a>{`,
you can call `}<inlineCode parentName="p">{`ResponseConverterFunction.fallthrough()`}</inlineCode>{` when your response converter is not able to
convert the result to an `}<a parentName="p" {...{
        "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
      }}>{`type://HttpResponse`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyResponseConverter implements ResponseConverterFunction {
    @Override
    HttpResponse convertResponse(ServiceRequestContext ctx,
                                 ResponseHeaders headers,
                                 @Nullable Object result,
                                 HttpHeaders trailers) throws Exception {
        if (result instanceof MyObject) {
            return HttpResponse.of(HttpStatus.OK,
                                   MediaType.PLAIN_TEXT_UTF_8,
                                   "Hello, %s!", ((MyObject) result).processedName(),
                                   trailers);
        }

        // To the next response converter.
        return ResponseConverterFunction.fallthrough();
    }
}
`}</code></pre>
    <p>{`You can annotate your service method and class as follows.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@ResponseConverter(MyResponseConverter.class)
public class MyAnnotatedService {

    @Post("/hello")
    public MyObject hello() {
        // MyResponseConverter will be used to make a response.
        // ...
    }

    @Post("/hola")
    @ResponseConverter(MySpanishResponseConverter.class)
    public MyObject hola() {
        // MySpanishResponseConverter will be tried to convert MyObject
        // to a response first, and then MyResponseConverter will be used
        // if MySpanishResponseConverter fell through.
        // ...
    }
}
`}</code></pre>
    <p>{`Armeria supports `}<a parentName="p" {...{
        "href": "#media-type-negotiation"
      }}>{`Media type negotiation`}</a>{`. So you may want to get
a negotiated media type in order to set a `}<inlineCode parentName="p">{`Content-Type`}</inlineCode>{` header on your response.
In this case, you can access it in your response converter as follows.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyResponseConverter implements ResponseConverterFunction {
    @Override
    HttpResponse convertResponse(ServiceRequestContext ctx,
                                 ResponseHeaders headers,
                                 @Nullable Object result,
                                 HttpHeaders trailers) throws Exception {
        MediaType mediaType = ctx.negotiatedResponseMediaType();
        if (mediaType != null) {
            // Do something based on the media type.
            // ...
        }
    }
}
`}</code></pre>
    <p>{`Even if you do not specify any `}<a parentName="p" {...{
        "href": "type://@ResponseConverter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ResponseConverter.html"
      }}>{`type://@ResponseConverter`}</a>{` annotation, the response object can be converted into
an `}<a parentName="p" {...{
        "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
      }}>{`type://HttpResponse`}</a>{` by one of the following response converters which performs the conversion based on
the negotiated media type and the type of the object.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://JacksonResponseConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/JacksonResponseConverterFunction.html"
          }}>{`type://JacksonResponseConverterFunction`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`converts an object to a JSON document if the negotiated media type is `}<inlineCode parentName="li">{`application/json`}</inlineCode>{`.
`}<inlineCode parentName="li">{`JsonNode`}</inlineCode>{` object can be converted to a JSON document even if there is no media type negotiated.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://StringResponseConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/StringResponseConverterFunction.html"
          }}>{`type://StringResponseConverterFunction`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`converts an object to a string if the negotiated main media type is one of `}<inlineCode parentName="li">{`text`}</inlineCode>{` types.
If there is no media type negotiated, `}<inlineCode parentName="li">{`String`}</inlineCode>{` and `}<inlineCode parentName="li">{`CharSequence`}</inlineCode>{` object will be written as a text
with `}<inlineCode parentName="li">{`Content-Type: text/plain; charset=utf-8`}</inlineCode>{` header.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://ByteArrayResponseConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ByteArrayResponseConverterFunction.html"
          }}>{`type://ByteArrayResponseConverterFunction`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`converts an object to a byte array. Only `}<a parentName="li" {...{
              "href": "type://HttpData:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpData.html"
            }}>{`type://HttpData`}</a>{` and `}<inlineCode parentName="li">{`byte[]`}</inlineCode>{` will be handled
even if the negotiated media type is `}<inlineCode parentName="li">{`application/binary`}</inlineCode>{` or `}<inlineCode parentName="li">{`application/octet-stream`}</inlineCode>{`.
If there is no media type negotiated, `}<a parentName="li" {...{
              "href": "type://HttpData:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpData.html"
            }}>{`type://HttpData`}</a>{` and `}<inlineCode parentName="li">{`byte[]`}</inlineCode>{` object will be written as a binary
with `}<inlineCode parentName="li">{`Content-Type: application/binary`}</inlineCode>{` header.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Let's see the following example about the default response conversion.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    // JacksonResponseConverterFunction will convert the return values
    // to JSON documents:
    @Get("/json1")
    @ProducesJson    // the same as @Produces("application/json; charset=utf-8")
    public MyObject json1() { ... }

    @Get("/json2")
    public JsonNode json2() { ... }

    // StringResponseConverterFunction will convert the return values to strings:
    @Get("/string1")
    @ProducesText    // the same as @Produces("text/plain; charset=utf-8")
    public int string1() { ... }

    @Get("/string2")
    public CharSequence string2() { ... }

    // ByteArrayResponseConverterFunction will convert the return values
    // to byte arrays:
    @Get("/byte1")
    @ProducesBinary  // the same as @Produces("application/binary")
    public HttpData byte1() { ... }

    @Get("/byte2")
    public byte[] byte2() { ... }
}
`}</code></pre>
    <h2 {...{
      "id": "specifying-a-blocking-task-executor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#specifying-a-blocking-task-executor",
        "aria-label": "specifying a blocking task executor permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Specifying a blocking task executor`}</h2>
    <p>{`An annotated service is executed by an `}<a parentName="p" {...{
        "href": "https://netty.io/4.1/api/io/netty/channel/EventLoop.html"
      }}>{`EventLoop`}</a>{`,
so you must not make a blocking call within the `}<inlineCode parentName="p">{`EventLoop`}</inlineCode>{`. If you want to make a blocking call,
you should delegate the call to another thread. You can use your own thread or a blocking task executor that
Armeria provides.`}</p>
    <p>{`If you specify the `}<a parentName="p" {...{
        "href": "type://@Blocking:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Blocking.html"
      }}>{`type://@Blocking`}</a>{` annotation to the method, the method will be executed by
the blocking task executor that is returned by `}<a parentName="p" {...{
        "href": "type://ServiceRequestContext#blockingTaskExecutor():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#blockingTaskExecutor()"
      }}>{`type://ServiceRequestContext#blockingTaskExecutor()`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    @Blocking // 👈
    @Get("/hello")
    public HttpResponse hello(ServiceRequestContext ctx) {
        // ctx.blockingTaskExecutor() executes this method.
        assert !ctx.eventLoop().inEventLoop();

        try {
            Statement stmt = ...;
            // Make a blocking call.
            ResultSet resultSet = stmt.executeQuery(...);
            return responseFrom(resultSet);
        } catch (Throwable t) {
            return HttpResponse.of(HttpStatus.INTERNAL_SERVER_ERROR);
        }
    }
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`You can apply the `}<a parentName="p" {...{
          "href": "type://@Blocking:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Blocking.html"
        }}>{`type://@Blocking`}</a>{` annotation at the class level so all methods are executed by the
blocking task executor:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-java"
        }}>{`@Blocking // 👈
public class MyBlockingAnnotatedService {
    @Get("/hello")
    public HttpResponse hello(ServiceRequestContext ctx) {
        // ctx.blockingTaskExecutor() executes this method.
        ...
    }
    @Get("/hello2")
    public HttpResponse hello2(ServiceRequestContext ctx) {
        // ctx.blockingTaskExecutor() executes this method.
        ...
    }
}
`}</code></pre>
    </Tip>
    <p>{`You can also submit a task to the `}<a parentName="p" {...{
        "href": "type://ServiceRequestContext#blockingTaskExecutor():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#blockingTaskExecutor()"
      }}>{`type://ServiceRequestContext#blockingTaskExecutor()`}</a>{` or any `}<inlineCode parentName="p">{`Executor`}</inlineCode>{`
instead of using the `}<a parentName="p" {...{
        "href": "type://@Blocking:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Blocking.html"
      }}>{`type://@Blocking`}</a>{` annotation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    @Get("/hello")
    public HttpResponse hello(ServiceRequestContext ctx) {
        // The current thread is an event loop.
        assert ctx.eventLoop().inEventLoop();

        CompletableFuture<HttpResponse> future = new CompletableFuture<>();
        // Delegate a blocking call to the blocking task executor.
        ctx.blockingTaskExecutor().execute(() -> { // 👈
            try {
                Statement stmt = ...;
                // Make a blocking call.
                ResultSet resultSet = stmt.executeQuery(...);
                HttpResponse res = responseFrom(resultSet);
                future.complete(res);
            } catch (Throwable t) {
                future.complete(HttpResponse.of(HttpStatus.INTERNAL_SERVER_ERROR));
            }
        });
        return HttpResponse.from(future);
    }
`}</code></pre>
    <h2 {...{
      "id": "using-serverbuilder-to-configure-converters-and-exception-handlers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-serverbuilder-to-configure-converters-and-exception-handlers",
        "aria-label": "using serverbuilder to configure converters and exception handlers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using `}<inlineCode parentName="h2">{`ServerBuilder`}</inlineCode>{` to configure converters and exception handlers`}</h2>
    <p>{`You can specify converters and exception handlers using `}<a parentName="p" {...{
        "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
      }}>{`type://ServerBuilder`}</a>{`, without using the annotations
explained in the previous sections:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`sb.annotatedService(new MyAnnotatedService(),
                    // Exception handlers
                    new MyExceptionHandler(),
                    // Converters
                    new MyRequestConverter(), new MyResponseConverter());
`}</code></pre>
    <p>{`Also, they have a different method signature for conversion and exception handling so you can even write them
in a single class and add it to your `}<a parentName="p" {...{
        "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
      }}>{`type://ServerBuilder`}</a>{` at once, e.g.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAllInOneHandler implements RequestConverterFunction,
                                          ResponseConverterFunction,
                                          ExceptionHandlerFunction {
    @Override
    public Object convertRequest(ServiceRequestContext ctx,
                                 AggregatedHttpRequest request,
                                 Class<?> expectedResultType) { ... }

    @Override
    HttpResponse convertResponse(ServiceRequestContext ctx,
                                 ResponseHeaders headers,
                                 @Nullable Object result,
                                 HttpHeaders trailers) throws Exception { ... }

    @Override
    public HttpResponse handleException(ServiceRequestContext ctx,
                                        HttpRequest req, Throwable cause) { ... }
}

// ...

sb.annotatedService(new MyAnnotatedService(), new MyAllInOneHandler());
`}</code></pre>
    <p>{`When you specify exception handlers in a mixed manner like below, they will be evaluated in the following
order commented. It is also the same as the evaluation order of the converters.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@ExceptionHandler(MyClassExceptionHandler3.class)           // order 3
@ExceptionHandler(MyClassExceptionHandler4.class)           // order 4
public class MyAnnotatedService {
    @Get("/hello")
    @ExceptionHandler(MyMethodExceptionHandler1.class)      // order 1
    @ExceptionHandler(MyMethodExceptionHandler2.class)      // order 2
    public HttpResponse hello() { ... }
}

// ...

sb.annotatedService(new MyAnnotatedService(),
                    new MyGlobalExceptionHandler5(),        // order 5
                    new MyGlobalExceptionHandler6());       // order 6
`}</code></pre>
    <h2 {...{
      "id": "returning-a-response",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#returning-a-response",
        "aria-label": "returning a response permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Returning a response`}</h2>
    <p>{`In the earlier examples, the annotated service methods only return `}<a parentName="p" {...{
        "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
      }}>{`type://HttpResponse`}</a>{`, however there are
more response types which can be used in the annotated service.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{` and `}<a parentName="p" {...{
            "href": "type://AggregatedHttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/AggregatedHttpResponse.html"
          }}>{`type://AggregatedHttpResponse`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`It will be sent to the client without any modification. If an exception is raised while the response is
being sent, exception handlers will handle it. If no message has been sent to the client yet,
the exception handler can send an `}<a parentName="li" {...{
              "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
            }}>{`type://HttpResponse`}</a>{` instead.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://ResponseEntity:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ResponseEntity.html"
          }}>{`type://ResponseEntity`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`It contains the `}<a parentName="li" {...{
              "href": "type://HttpHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpHeaders.html"
            }}>{`type://HttpHeaders`}</a>{` and the object which can be converted into HTTP response body by
response converters. A user can customize the HTTP status and headers including the trailers, with this type.`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`public class MyAnnotatedService {
    @Get("/users")
    public ResponseEntity<List<User>> getUsers(@Param int start) {
        List<User> users = ...;
        ResponseHeaders headers = ResponseHeaders.builder()
            .status(HttpStatus.OK)
            .add(HttpHeaderNames.LINK,
                 String.format("<https://example.com/users?from=%s>; rel=\\"next\\"",
                               start + 10))
            .build();
        return ResponseEntity.of(headers, users);
    }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Reactive Streams `}<a parentName="p" {...{
            "href": "https://www.reactive-streams.org/reactive-streams-1.0.2-javadoc/org/reactivestreams/Publisher.html"
          }}>{`Publisher`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`All objects which are produced by the publisher will be collected, then the collected ones will be
converted to an `}<a parentName="li" {...{
              "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
            }}>{`type://HttpResponse`}</a>{` by response converters. If a single object is produced, it will be
passed into the response converters as it is. But if multiple objects are produced, they will be passed
into the response converters as a list. If the producer produces an error, exception handlers will handle it.
Note that RxJava `}<a parentName="li" {...{
              "href": "http://reactivex.io/RxJava/javadoc/io/reactivex/ObservableSource.html"
            }}>{`ObservableSource`}</a>{`
will be treated in the same way as `}<a parentName="li" {...{
              "href": "https://www.reactive-streams.org/reactive-streams-1.0.2-javadoc/org/reactivestreams/Publisher.html"
            }}>{`Publisher`}</a>{` if you add `}<inlineCode parentName="li">{`armeria-rxjava`}</inlineCode>{` to the dependencies.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`CompletionStage`}</inlineCode>{` and `}<inlineCode parentName="p">{`CompletableFuture`}</inlineCode></p>
        <ul parentName="li">
          <li parentName="ul">{`An object which is generated by the `}<inlineCode parentName="li">{`CompletionStage`}</inlineCode>{` will be converted to an `}<a parentName="li" {...{
              "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
            }}>{`type://HttpResponse`}</a>{`
by response converters. If the `}<inlineCode parentName="li">{`CompletionStage`}</inlineCode>{` completes exceptionally, exception handlers will
handle the cause.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Other types`}</p>
        <ul parentName="li">
          <li parentName="ul">{`As described in
`}<a parentName="li" {...{
              "href": "#converting-a-java-object-to-an-http-response"
            }}>{`Converting a Java object to an HTTP response`}</a>{`,
you can use any response types with response converters that convert them.
If a service method raises an exception, exception handlers will handle it.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "decorating-an-annotated-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#decorating-an-annotated-service",
        "aria-label": "decorating an annotated service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Decorating an annotated service`}</h2>
    <p>{`Every `}<a parentName="p" {...{
        "href": "type://HttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpService.html"
      }}>{`type://HttpService`}</a>{` can be wrapped by another `}<a parentName="p" {...{
        "href": "type://HttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpService.html"
      }}>{`type://HttpService`}</a>{` in Armeria (Refer to
`}<a parentName="p" {...{
        "href": "/docs/server-decorator"
      }}>{`Decorating a service`}</a>{` for more information).
Simply, you can write your own decorator by
implementing `}<a parentName="p" {...{
        "href": "type://DecoratingHttpServiceFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/DecoratingHttpServiceFunction.html"
      }}>{`type://DecoratingHttpServiceFunction`}</a>{` interface as follows.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyDecorator implements DecoratingHttpServiceFunction {
    @Override
    public HttpResponse serve(HttpService delegate,
                              ServiceRequestContext ctx,
                              HttpRequest req) {
        // ... Do something ...
        return delegate.serve(ctx, req);
    }
}
`}</code></pre>
    <p>{`Then, annotate your class or method with a `}<a parentName="p" {...{
        "href": "type://@Decorator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Decorator.html"
      }}>{`type://@Decorator`}</a>{` annotation. In the following example,
`}<inlineCode parentName="p">{`MyDecorator`}</inlineCode>{` will handle a request first, then `}<inlineCode parentName="p">{`AnotherDecorator`}</inlineCode>{` will handle the request next,
and finally `}<inlineCode parentName="p">{`hello()`}</inlineCode>{` method will handle the request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Decorator(MyDecorator.class)
public class MyAnnotatedService {
    @Decorator(AnotherDecorator.class)
    @Get("/hello")
    public HttpResponse hello() { ... }
}
`}</code></pre>
    <h3 {...{
      "id": "decorating-an-annotated-service-with-a-custom-decorator-annotation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#decorating-an-annotated-service-with-a-custom-decorator-annotation",
        "aria-label": "decorating an annotated service with a custom decorator annotation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Decorating an annotated service with a custom decorator annotation`}</h3>
    <p>{`As you read earlier, you can write your own decorator with `}<a parentName="p" {...{
        "href": "type://DecoratingHttpServiceFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/DecoratingHttpServiceFunction.html"
      }}>{`type://DecoratingHttpServiceFunction`}</a>{` interface.
If your decorator does not require any parameter, that is fine. However, what if your decorator requires
a parameter? In this case, you can create your own decorator annotation. Let's see the following custom
decorator annotation which applies `}<a parentName="p" {...{
        "href": "type://LoggingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/LoggingService.html"
      }}>{`type://LoggingService`}</a>{` to an annotated service.`}</p>
    <Tip mdxType="Tip">
      <p>{`This example is actually just a copy of what Armeria provides out of the box. In reality,
you could just use `}<a parentName="p" {...{
          "href": "type://@LoggingDecorator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/decorator/LoggingDecorator.html"
        }}>{`type://@LoggingDecorator`}</a>{`, without writing your own one.`}</p>
    </Tip>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@DecoratorFactory(LoggingDecoratorFactoryFunction.class)
@Retention(RetentionPolicy.RUNTIME)
@Target({ ElementType.TYPE, ElementType.METHOD })
public @interface LoggingDecorator {

    // Specify parameters for your decorator like below.
    LogLevel requestLogLevel() default LogLevel.TRACE;

    LogLevel successfulResponseLogLevel() default LogLevel.TRACE;

    LogLevel failureResponseLogLevel() default LogLevel.WARN;

    float samplingRate() default 1.0f;

    // A special parameter in order to specify the order of a decorator.
    int order() default 0;
}

public final class LoggingDecoratorFactoryFunction
        implements DecoratorFactoryFunction<LoggingDecorator> {

    @Override
    public Function<? super HttpService, ? extends HttpService>
    newDecorator(LoggingDecorator parameter) {
        return LoggingService
            .builder()
            .requestLogLevel(parameter.requestLogLevel())
            .successfulResponseLogLevel(parameter.successfulResponseLogLevel())
            .failureResponseLogLevel(parameter.failureResponseLogLevel())
            .samplingRate(parameter.samplingRate())
            .newDecorator();
    }
}
`}</code></pre>
    <p>{`You can see `}<a parentName="p" {...{
        "href": "type://@DecoratorFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/DecoratorFactory.html"
      }}>{`type://@DecoratorFactory`}</a>{` annotation at the first line of the example. It specifies
a factory class which implements `}<a parentName="p" {...{
        "href": "type://DecoratorFactoryFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/DecoratorFactoryFunction.html"
      }}>{`type://DecoratorFactoryFunction`}</a>{` interface. The factory will create
an instance of `}<a parentName="p" {...{
        "href": "type://LoggingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/LoggingService.html"
      }}>{`type://LoggingService`}</a>{` with parameters which you specified on the class or method like below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {
    @LoggingDecorator(requestLogLevel = LogLevel.INFO)
    @Get("/hello1")
    public HttpResponse hello1() { ... }

    @LoggingDecorator(requestLogLevel = LogLevel.DEBUG, samplingRate = 0.05)
    @Get("/hello2")
    public HttpResponse hello2() { ... }
}
`}</code></pre>
    <h3 {...{
      "id": "evaluation-order-of-decorators",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#evaluation-order-of-decorators",
        "aria-label": "evaluation order of decorators permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Evaluation order of decorators`}</h3>
    <p>{`Note that the evaluation order of the decorators is slightly different from that of the converters and exception
handlers. As you read in `}<a parentName="p" {...{
        "href": "#using-serverbuilder-to-configure-converters-and-exception-handlers"
      }}>{`Using ServerBuilder to configure converters and exception
handlers`}</a>{`,
both the converters and exception handlers are applied in the order of method-level ones,
class-level ones and global ones.
Unlike them, decorators are applied in the opposite order as follows,
because it is more understandable for a user to apply from the outer decorators to the inner decorators,
which means the order of global decorators, class-level decorators and method-level decorators.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Decorator(MyClassDecorator2.class)                 // order 2
@Decorator(MyClassDecorator3.class)                 // order 3
public class MyAnnotatedService {

    @Get("/hello")
    @Decorator(MyMethodDecorator4.class)            // order 4
    @Decorator(MyMethodDecorator5.class)            // order 5
    public HttpResponse hello() { ... }
}

// ...

sb.annotatedService(new MyAnnotatedService(),
                    new MyGlobalDecorator1());      // order 1
`}</code></pre>
    <p>{`The first rule is as explained before. However, if your own decorator annotations and `}<a parentName="p" {...{
        "href": "type://@Decorator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Decorator.html"
      }}>{`type://@Decorator`}</a>{`
annotations are specified in a mixed order like below, you need to clearly specify their order using `}<a parentName="p" {...{
        "href": "type://@Decorator#order()"
      }}>{`type://@Decorator#order()`}</a>{`
attribute of the annotation. In the following example, you cannot make sure in what order they decorate
the service because Java collects repeatable annotations like `}<a parentName="p" {...{
        "href": "type://@Decorator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Decorator.html"
      }}>{`type://@Decorator`}</a>{` into a single container
annotation like `}<a parentName="p" {...{
        "href": "type://@Decorators:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Decorators.html"
      }}>{`type://@Decorators`}</a>{` so it does not know the specified order between `}<a parentName="p" {...{
        "href": "type://@Decorator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Decorator.html"
      }}>{`type://@Decorator`}</a>{`
and `}<a parentName="p" {...{
        "href": "type://@LoggingDecorator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/decorator/LoggingDecorator.html"
      }}>{`type://@LoggingDecorator`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    @Get("/hello")
    @Decorator(MyMethodDecorator1.class)
    @LoggingDecorator
    @Decorator(MyMethodDecorator2.class)
    public HttpResponse hello() { ... }
}
`}</code></pre>
    <p>{`To enforce the evaluation order of decorators, you can use `}<inlineCode parentName="p">{`order()`}</inlineCode>{` attribute. Lower the order value is,
earlier the decorator will be executed. The default value of `}<inlineCode parentName="p">{`order()`}</inlineCode>{` attribute is `}<inlineCode parentName="p">{`0`}</inlineCode>{`.
The `}<inlineCode parentName="p">{`order()`}</inlineCode>{` attribute is applicable only to class-level and method-level decorators.`}</p>
    <p>{`With the following example, the `}<inlineCode parentName="p">{`hello()`}</inlineCode>{` will be executed with the following order:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`MyGlobalDecorator1`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`MyMethodDecorator1`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`LoggingDecorator`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`MyMethodDecorator2`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`MyAnnotatedService.hello()`}</inlineCode></li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    @Get("/hello")
    @Decorator(value = MyMethodDecorator1.class, order = 1)
    @LoggingDecorator(order = 2)
    @Decorator(value = MyMethodDecorator2.class, order = 3)
    public HttpResponse hello() { ... }
}

// Global-level decorators will not be affected by 'order'.
sb.annotatedService(new MyAnnotatedService(),
                    new MyGlobalDecorator1());
`}</code></pre>
    <p>{`Note that you can even make a method-level decorator executed before a class-level decorator
by adjusting the `}<inlineCode parentName="p">{`order()`}</inlineCode>{` attribute:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@LoggingDecorator
public class MyAnnotatedService {

    // LoggingDecorator -> MyMethodDecorator1 -> hello1()
    @Get("/hello1")
    @Decorator(MyMethodDecorator1.class)
    public HttpResponse hello1() { ... }

    // MyMethodDecorator1 -> LoggingDecorator -> hello2()
    @Get("/hello2")
    @Decorator(value = MyMethodDecorator1.class, order = -1)
    public HttpResponse hello2() { ... }
}
`}</code></pre>
    <p>{`If you built a custom decorator annotation like `}<a parentName="p" {...{
        "href": "type://@LoggingDecorator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/decorator/LoggingDecorator.html"
      }}>{`type://@LoggingDecorator`}</a>{`, it is recommended to
add an `}<inlineCode parentName="p">{`order()`}</inlineCode>{` attribute so that the user of the custom annotation is able to adjust
the order value of the decorator:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public @interface MyDecoratorAnnotation {

    // Define your attributes.
    int myAttr1();

    // A special parameter in order to specify the order of a decorator.
    int order() default 0;
}
`}</code></pre>
    <h2 {...{
      "id": "media-type-negotiation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#media-type-negotiation",
        "aria-label": "media type negotiation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Media type negotiation`}</h2>
    <p>{`Armeria provides `}<a parentName="p" {...{
        "href": "type://@Produces:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Produces.html"
      }}>{`type://@Produces`}</a>{` and `}<a parentName="p" {...{
        "href": "type://@Consumes:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Consumes.html"
      }}>{`type://@Consumes`}</a>{` annotations to support media type
negotiation. It is not necessary if you have only one service method for a path and an HTTP method.
However, assume that you have multiple service methods for the same path and the same HTTP method as follows.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    @Get("/hello")
    public HttpResponse hello1() {
        // Return a text document to the client.
        return HttpResponse.of(HttpStatus.OK, MediaType.PLAIN_TEXT_UTF_8,
                               "Armeria");
    }

    @Get("/hello")
    public HttpResponse hello2() {
        // Return a JSON object to the client.
        return HttpResponse.of(HttpStatus.OK, MediaType.JSON_UTF_8,
                               "{ \\"name\\": \\"Armeria\\" }");
    }
}
`}</code></pre>
    <p>{`If the media type is not specified on any methods bound to the same path pattern, the first method declared will
be used and the other methods will be ignored. In this example, `}<inlineCode parentName="p">{`hello1()`}</inlineCode>{` will be chosen and the client
will always receive a text document. What if you want to get a JSON object from the path `}<inlineCode parentName="p">{`/hello`}</inlineCode>{`?
You can just specify the type of the content which your method produces as follows and add an `}<inlineCode parentName="p">{`Accept`}</inlineCode>{` header
to your client request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    @Get("/hello")
    @Produces("text/plain")
    public HttpResponse helloText() {
        // Return a text document to the client.
        return HttpResponse.of(HttpStatus.OK, MediaType.PLAIN_TEXT_UTF_8,
                               "Armeria");
    }

    @Get("/hello")
    @Produces("application/json")
    public HttpResponse helloJson() {
        // Return a JSON object to the client.
        return HttpResponse.of(HttpStatus.OK, MediaType.JSON_UTF_8,
                               "{ \\"name\\": \\"Armeria\\" }");
    }
}
`}</code></pre>
    <p>{`A request like the following would get a text document:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-http"
      }}>{`GET /hello HTTP/1.1
Accept: text/plain
`}</code></pre>
    <p>{`A request like the following would get a JSON object:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-http"
      }}>{`GET /hello HTTP/1.1
Accept: application/json
`}</code></pre>
    <Warning mdxType="Warning">
      <p>{`Note that a `}<inlineCode parentName="p">{`Content-Type`}</inlineCode>{` header of a response is not automatically set. You may want to get the
negotiated `}<a parentName="p" {...{
          "href": "type://@Produces:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Produces.html"
        }}>{`type://@Produces`}</a>{` from `}<a parentName="p" {...{
          "href": "type://ServiceRequestContext#negotiatedResponseMediaType():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#negotiatedResponseMediaType()"
        }}>{`type://ServiceRequestContext#negotiatedResponseMediaType()`}</a>{` method and
set it as the value of the `}<inlineCode parentName="p">{`Content-Type`}</inlineCode>{` header of your response.`}</p>
    </Warning>
    <p>{`If a client sends a request without an `}<inlineCode parentName="p">{`Accept`}</inlineCode>{` header (or sending an `}<inlineCode parentName="p">{`Accept`}</inlineCode>{` header with an unsupported
content type), it would be usually mapped to `}<inlineCode parentName="p">{`helloJson()`}</inlineCode>{` method because the methods are sorted by the
name of the type in an alphabetical order.`}</p>
    <p>{`In this case, you can adjust the order of the methods with `}<a parentName="p" {...{
        "href": "type://@Order:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Order.html"
      }}>{`type://@Order`}</a>{` annotation. The default value of
`}<a parentName="p" {...{
        "href": "type://@Order:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Order.html"
      }}>{`type://@Order`}</a>{` annotation is `}<inlineCode parentName="p">{`0`}</inlineCode>{`. If you set the value less than `}<inlineCode parentName="p">{`0`}</inlineCode>{`, the method is used earlier than
the other methods, which means that it would be used as a default when there is no matched produce type.
In this example, it would also make the same effect to annotate `}<inlineCode parentName="p">{`helloJson()`}</inlineCode>{` with `}<inlineCode parentName="p">{`@Order(1)`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    @Order(-1)
    @Get("/hello")
    @Produces("text/plain")
    public HttpResponse helloText() {
        // Return a text document to the client.
        return HttpResponse.of(HttpStatus.OK, MediaType.PLAIN_TEXT_UTF_8,
                               "Armeria");
    }

    @Get("/hello")
    @Produces("application/json")
    public HttpResponse helloJson() {
        // Return a JSON object to the client.
        return HttpResponse.of(HttpStatus.OK, MediaType.JSON_UTF_8,
                               "{ \\"name\\": \\"Armeria\\" }");
    }
}
`}</code></pre>
    <p>{`Next, let's learn how to handle a `}<inlineCode parentName="p">{`Content-Type`}</inlineCode>{` header of a request. Assume that there are two service
methods that expect a text document and a JSON object as a content of a request, respectively.
You can annotate them with `}<a parentName="p" {...{
        "href": "type://@Consumes:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Consumes.html"
      }}>{`type://@Consumes`}</a>{` annotation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    @Post("/hello")
    @Consumes("text/plain")
    public HttpResponse helloText(AggregatedHttpRequest request) {
        // Get a text content by calling request.contentAscii().
    }

    @Post("/hello")
    @Consumes("application/json")
    public HttpResponse helloJson(AggregatedHttpRequest request) {
        // Get a JSON object by calling request.contentUtf8().
    }
}
`}</code></pre>
    <p>{`A request like the following would be handled by `}<inlineCode parentName="p">{`helloText()`}</inlineCode>{` method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-http"
      }}>{`POST /hello HTTP/1.1
Content-Type: text/plain
Content-Length: 7

Armeria
`}</code></pre>
    <p>{`A request like the following would be handled by `}<inlineCode parentName="p">{`helloJson()`}</inlineCode>{` method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-http"
      }}>{`POST /hello HTTP/1.1
Content-Type: application/json
Content-Length: 21

{ "name": "Armeria" }
`}</code></pre>
    <p>{`However, if a client sends a request with a `}<inlineCode parentName="p">{`Content-Type: application/octet-stream`}</inlineCode>{` header which is not
specified with `}<a parentName="p" {...{
        "href": "type://@Consumes:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Consumes.html"
      }}>{`type://@Consumes`}</a>{`, the client would get an HTTP status code of 415 which means
`}<inlineCode parentName="p">{`Unsupported Media Type`}</inlineCode>{`. If you want to make one of the methods catch-all, you can remove the annotation
as follows. `}<inlineCode parentName="p">{`helloCatchAll()`}</inlineCode>{` method would accept every request except for the request with a
`}<inlineCode parentName="p">{`Content-Type: application/json`}</inlineCode>{` header.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    @Post("/hello")
    public HttpResponse helloCatchAll(AggregatedHttpRequest request) {
        // Get a content by calling request.content() and handle it as a text document or something else.
    }

    @Post("/hello")
    @Consumes("application/json")
    public HttpResponse helloJson(AggregatedHttpRequest request) {
        // Get a JSON object by calling request.contentUtf8().
    }
}
`}</code></pre>
    <h3 {...{
      "id": "creating-user-defined-media-type-annotations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#creating-user-defined-media-type-annotations",
        "aria-label": "creating user defined media type annotations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating user-defined media type annotations`}</h3>
    <p>{`Armeria provides pre-defined annotations such as `}<a parentName="p" {...{
        "href": "type://@ConsumesJson:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ConsumesJson.html"
      }}>{`type://@ConsumesJson`}</a>{`, `}<a parentName="p" {...{
        "href": "type://@ConsumesText:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ConsumesText.html"
      }}>{`type://@ConsumesText`}</a>{`,
`}<a parentName="p" {...{
        "href": "type://@ConsumesBinary:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ConsumesBinary.html"
      }}>{`type://@ConsumesBinary`}</a>{` and `}<a parentName="p" {...{
        "href": "type://@ConsumesOctetStream:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ConsumesOctetStream.html"
      }}>{`type://@ConsumesOctetStream`}</a>{` which are aliases for
`}<inlineCode parentName="p">{`@Consumes("application/json; charset=utf-8")`}</inlineCode>{`, `}<inlineCode parentName="p">{`@Consumes("text/plain; charset=utf-8")`}</inlineCode>{`,
`}<inlineCode parentName="p">{`@Consumes("application/binary")`}</inlineCode>{` and `}<inlineCode parentName="p">{`@Consumes("application/octet-stream")`}</inlineCode>{` respectively.
Also, `}<a parentName="p" {...{
        "href": "type://@ProducesJson:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ProducesJson.html"
      }}>{`type://@ProducesJson`}</a>{`, `}<a parentName="p" {...{
        "href": "type://@ProducesText:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ProducesText.html"
      }}>{`type://@ProducesText`}</a>{`, `}<a parentName="p" {...{
        "href": "type://@ProducesBinary:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ProducesBinary.html"
      }}>{`type://@ProducesBinary`}</a>{` and `}<a parentName="p" {...{
        "href": "type://@ProducesOctetStream:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ProducesOctetStream.html"
      }}>{`type://@ProducesOctetStream`}</a>{`
are provided in the same manner.`}</p>
    <p>{`If there is no annotation that meets your need, you can define your own annotations for `}<a parentName="p" {...{
        "href": "type://@Consumes:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Consumes.html"
      }}>{`type://@Consumes`}</a>{`
and `}<a parentName="p" {...{
        "href": "type://@Produces:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Produces.html"
      }}>{`type://@Produces`}</a>{` as follows. Specifying your own annotations is recommended because writing a media type
with a string is more error-prone.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Retention(RetentionPolicy.RUNTIME)
@Target({ ElementType.TYPE, ElementType.METHOD })
@Consumes("application/xml")
public @interface MyConsumableType {}

@Retention(RetentionPolicy.RUNTIME)
@Target({ ElementType.TYPE, ElementType.METHOD })
@Produces("application/xml")
public @interface MyProducibleType {}
`}</code></pre>
    <p>{`Then, you can annotate your service method with your annotation as follows.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {
    @Post("/hello")
    @MyConsumableType  // the same as @Consumes("application/xml")
    @MyProducibleType  // the same as @Produces("application/xml")
    public MyResponse hello(MyRequest myRequest) { ... }
}
`}</code></pre>
    <h2 {...{
      "id": "specifying-additional-response-headerstrailers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#specifying-additional-response-headerstrailers",
        "aria-label": "specifying additional response headerstrailers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Specifying additional response headers/trailers`}</h2>
    <p>{`Armeria provides a way to configure additional headers/trailers via annotation,
`}<a parentName="p" {...{
        "href": "type://@AdditionalHeader:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/AdditionalHeader.html"
      }}>{`type://@AdditionalHeader`}</a>{` for HTTP headers and `}<a parentName="p" {...{
        "href": "type://@AdditionalTrailer:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/AdditionalTrailer.html"
      }}>{`type://@AdditionalTrailer`}</a>{` for HTTP trailers.`}</p>
    <p>{`You can annotate your service method with the annotations as follows.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.annotation.AdditionalHeader;
import com.linecorp.armeria.server.annotation.AdditionalTrailer;

@AdditionalHeader(name = "custom-header", value = "custom-value")
@AdditionalTrailer(name = "custom-trailer", value = "custom-value")
public class MyAnnotatedService {
    @Get("/hello")
    @AdditionalHeader(name = "custom-header-2", value = "custom-value")
    @AdditionalTrailer(name = "custom-trailer-2", value = "custom-value")
    public HttpResponse hello() { ... }
}
`}</code></pre>
    <p>{`The `}<a parentName="p" {...{
        "href": "type://@AdditionalHeader:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/AdditionalHeader.html"
      }}>{`type://@AdditionalHeader`}</a>{` or `}<a parentName="p" {...{
        "href": "type://@AdditionalTrailer:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/AdditionalTrailer.html"
      }}>{`type://@AdditionalTrailer`}</a>{` specified at the method level takes precedence over
what's specified at the class level if it has the same name, e.g.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@AdditionalHeader(name = "custom-header", value = "custom-value")
@AdditionalTrailer(name = "custom-trailer", value = "custom-value")
public class MyAnnotatedService {
    @Get("/hello")
    @AdditionalHeader(name = "custom-header", value = "custom-overwritten")
    @AdditionalTrailer(name = "custom-trailer", value = "custom-overwritten")
    public HttpResponse hello() { ... }
}
`}</code></pre>
    <p>{`In this case, the values of the HTTP header named `}<inlineCode parentName="p">{`custom-header`}</inlineCode>{` and the HTTP trailer named
`}<inlineCode parentName="p">{`custom-trailer`}</inlineCode>{` will be `}<inlineCode parentName="p">{`custom-overwritten`}</inlineCode>{`, not `}<inlineCode parentName="p">{`custom-value`}</inlineCode>{`.`}</p>
    <p>{`Note that the trailers will not be injected into the responses with the following HTTP status code,
because they always have an empty content.`}</p>
    <table><thead parentName="table"><tr parentName="thead"><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Status code`}</p></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Description`}</p></th></tr></thead><tbody parentName="table"><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`204`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`No content`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`205`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Reset content`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`304`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Not modified`}</p></td></tr></tbody></table>
    <h2 {...{
      "id": "using-a-composite-annotation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-a-composite-annotation",
        "aria-label": "using a composite annotation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using a composite annotation`}</h2>
    <p>{`To avoid specifying a common set of annotations repetitively, you may want to create a composite annotation
which is annotated by other annotations. For example, let's assume that there is a service class like the below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    @Post("/create")
    @ConsumesJson
    @ProducesJson
    @LoggingDecorator
    @MyAuthenticationDecorator
    public HttpResponse create() { ... }

    @Post("/update")
    @ConsumesJson
    @ProducesJson
    @LoggingDecorator
    @MyAuthenticationDecorator
    public HttpResponse update() { ... }
}
`}</code></pre>
    <p>{`In the above example, you had to add the same 4 annotations to the two different methods. It is obviously
too verbose and duplicate, so we could simplify them by creating a composite annotation like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Retention(RetentionPolicy.RUNTIME)
@ConsumesJson
@ProducesJson
@LoggingDecorator
@MyAuthenticationDecorator
public @interface MyCreateOrUpdateApiSpec {}
`}</code></pre>
    <p>{`Now, let's rewrite the service class with the composite annotation. It is definitely less verbose than before.
Moreover, you don't need to update both `}<inlineCode parentName="p">{`create()`}</inlineCode>{` and `}<inlineCode parentName="p">{`update()`}</inlineCode>{` but only `}<inlineCode parentName="p">{`MyCreateOrUpdateApiSpec`}</inlineCode>{`
when you add more common annotations to them.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyAnnotatedService {

    @Post("/create")
    @MyCreateOrUpdateApiSpec
    public HttpResponse create() { ... }

    @Post("/update")
    @MyCreateOrUpdateApiSpec
    public HttpResponse update() { ... }
}
`}</code></pre>
    <h2 {...{
      "id": "specifying-the-service-name",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#specifying-the-service-name",
        "aria-label": "specifying the service name permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Specifying the service name`}</h2>
    <p>{`A service name is human-readable string that is often used as a meter tag or distributed trace's span name.
By default, an annotated service uses its class name as its service name.
You can override it by annotating a class or method with `}<a parentName="p" {...{
        "href": "type://@ServiceName:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ServiceName.html"
      }}>{`type://@ServiceName`}</a>{` like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyService {
    @Get("/")
    public String get(ServiceRequestContext ctx) {
        ctx.log().whenAvailable(RequestLogProperty.NAME).thenAccept(log -> {
            assert log.serviceName().equals(MyService.class.getName());
        });
    }
}

// Override the default service name by the class annotation
@ServiceName("my-service")
public class MyService {
    @Get("/")
    public String get(ServiceRequestContext ctx) {
        ctx.log().whenAvailable(RequestLogProperty.NAME).thenAccept(log -> {
            assert log.serviceName().equals("my-service");
        });
    }

    // Override the default service name by the method annotation
    @ServiceName("my-post-service")
    @Post("/")
    public String post(ServiceRequestContext ctx) {
        ctx.log().whenAvailable(RequestLogProperty.NAME).thenAccept(log -> {
            assert log.serviceName().equals("my-post-service");
        });
    }
}

// Or the default name could get overridden programmatically using a decorator.
ServerBuilder sb = Server.builder();
sb.annotatedService("/", new MyService(), service -> {
    return service.decorate((delegate, ctx, req) -> {
        ctx.logBuilder().name("my-decorated-service", ctx.method().name());
        return delegate.serve(ctx, req);
    });
});
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`You can also use `}<a parentName="p" {...{
          "href": "type://RequestLogAccess#whenComplete():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLogAccess.html#whenComplete()"
        }}>{`type://RequestLogAccess#whenComplete()`}</a>{` to access `}<a parentName="p" {...{
          "href": "type://RequestOnlyLog#name():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestOnlyLog.html#name()"
        }}>{`type://RequestOnlyLog#name()`}</a>{`.
Please refer to `}<a parentName="p" {...{
          "href": "/docs/advanced-structured-logging"
        }}>{`Structured logging`}</a>{`
for more information about RPC and HTTP service names, and how to use it.`}</p>
    </Tip>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      